<template>
  <div class="anfangDetail">
    <div id="box"></div>
  </div>
</template>

<script>
import { getCareraListUrl } from "./api";
export default {
  name: "anfangDetail",
  data() {
    return {
      player: "",
      oDetail: {
        extra: {
          url: "",
        },
      },
      id: "",
      axiosHeader: {
        "Cockpit-Auth":
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjkyMjMzNzIwMzY4NTQ3NzU4MDcsInBob25lIjoiMTU5MTIzNDU2NzgiLCJ1c2VybmFtZSI6ImFkbWluX3lkIn0.K5cNEP6h76OBqHYSyYqHviXPJrPSNkbs2cxEgG-x0tA",
      },
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getActivityList();
  },
  beforeDestroy() {
    if (document.getElementById("box")) {
      document.getElementById("box").innerHTML = "";
    }
    this.player = null;
  },
  methods: {
    init() {
      let _this = this;
      document.getElementById("box").innerHTML = `<div
            id="J_prismPlayer"
            width="100%"
            height="100%"
            class="prism-player"
          ></div>`;
      this.player = new window.Aliplayer(
        {
          id: "J_prismPlayer", // 容器id
          source: _this.oDetail.extra.url, // 视频url
          width: "100%", // 播放器宽度
          height: "100%", // 播放器高度
          autoplay: true,
          isLive: true,
          rePlay: false,
          playsinline: true,
          preload: true,
          controlBarVisibility: "hover",
          useH5Prism: true,
        },
        function (player) {
          console.log("播放器创建了");
        }
      );
    },
    async getActivityList() {
      let params = {};
      let res = await this.$axios.get(`${getCareraListUrl}`, {
        headers: this.axiosHeader,
      });
      if (res.code == 0) {
        res.data.forEach((item) => {
          if (item.id == this.id) {
            this.oDetail = item;
          }
        });
        this.$nextTick(() => {
          this.init();
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.anfangDetail {
  #box {
    height: 500px;
  }
}
</style>
